<!-- 日期选择——提供快捷选项 -->
<template>
  <div id="datePicker">
    <div class="start">
      <span class="demonstration">开始日期:</span>
      <el-date-picker class="date" v-model="_value[0]" type="date" placeholder="开始日期" value-format="yyyyMMdd">
      </el-date-picker>
      <template v-if="isTime">
        <span class="demonstration">开始时间:</span>
        <el-time-picker class="date" v-model="_value[2]" type="datetime" placeholder="开始时间" value-format="HHmmss">
        </el-time-picker>
      </template>
    </div>
    <div class="end">
      <span class="demonstration">结束日期:</span>
      <el-date-picker class="date" v-model="_value[1]" type="date" placeholder="结束日期" value-format="yyyyMMdd">
      </el-date-picker>
      <template v-if="isTime">
        <span class="demonstration">结束时间:</span>
        <el-time-picker class="date" v-model="_value[3]" type="datetime" placeholder="结束时间" value-format="HHmmss">
        </el-time-picker>
      </template>
    </div>
    <!-- 
    <span>开始日期：</span>
    <el-date-picker type="daterange" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" v-model="_value" value-format="yyyyMMdd">
    </el-date-picker> -->
    <!-- :picker-options="pickerOptions" -->
  </div>
</template>

<script>
export default {
  data() {
    let _this = this;
    return {
      // startDate: '',
      // startTime: '',
      // endDate: '',
      // endTime: '',
      // pickerOptions: {
      //   // 今天之后不可选择
      //   disabledDate(time) {
      //     return time.getTime() > Date.now();
      //   },
      //   // 快捷选项
      //   shortcuts: [{
      //     text: '最近三天',
      //     onClick(picker) {
      //       let date = _this.setDate(2);
      //       picker.$emit('pick', date);
      //     }
      //   }, {
      //     text: '最近一周',
      //     onClick(picker) {
      //       let date = _this.setDate(6);
      //       picker.$emit('pick', date);
      //     }
      //   }, {
      //     text: '最近半个月',
      //     onClick(picker) {
      //       let date = _this.setDate(14);
      //       picker.$emit('pick', date);
      //     }
      //   }]
      // }
    }
  },
  props: {
    value: {
      type: [String, Object, Array]
    },
    isTime: {       //是否隐藏时间选择
      type: [Boolean],
      default: true
    }
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
        // this.$emit('change', val);
      }
    }
  },
  methods: {
    setDate(num) {
      const date = new Date();
      date.setTime(date.getTime() - 3600 * 1000 * 24 * num);
      let startDate = this.common.cGetDate('', date);
      let endDate = this.common.cGetDate('');
      let data = [startDate, endDate];
      return data;
    }
  }
}
</script>

<style lang="scss">
.demonstration {
  padding: 10px;
}
.start {
  float: left;
  margin-right: 10px;
}
.end {
  float: left;
}
.date {
  width: 130px !important;
  font-size: 12px !important;
}
</style>
