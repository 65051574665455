<!-- 查询栏 -->
<template>
  <div id="query">
    <div class="c-query">
      <slot name="front"></slot>
      <div class="item">
        <span>选择设备：</span>
        <el-select v-model="device" filterable placeholder="请选择" @change="handlerDevice">
          <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <DatePicker v-model="date"></DatePicker>
      </div>
      <div class="item">
        <el-button type="primary" icon="el-icon-search" @click="onEvent('query')">查询</el-button>
        <el-button v-if="isExcel" type="primary" icon="el-icon-upload2" plain @click="onEvent('excel')">导出到Excel</el-button>
      </div>
      <slot name="behind"></slot>
    </div>
  </div>
</template>

<script>
import DatePicker from '../components/DatePicker.vue';

export default {
  props: {
    isExcel: {
      type: Boolean,
      default: true   //默认显示导出excel按钮
    },
    startTime: {
      type: String,
      default: '080000'  //默认开始时间为08:00
    },
    stopTime: {
      type: String,
      default: '200000'  //默认结束时间20:00
    }
  },
  data() {
    return {
      device: '',
      deviceModel: '',
      deviceTitle: '',
      date: []
    }
  },
  components: {
    DatePicker
  },
  computed: {
    options() {
      return this.$store.getters.getCurDevices;
    }
  },
  watch: {
    options: {
      handler() {
        this.device = this.options[0].id;
        this.deviceModel = this.options[0].model;
        this.deviceTitle = this.options[0].title;
      }
    },
    device: {
      handler() {
        this.$emit('query', this.device, this.deviceModel, this.date, this.deviceTitle);
      }
    }
  },
  methods: {
    handlerDevice(val) {
      if (val) {
        let option = this.options.filter(e => e.id == val)
        this.deviceModel = option[0].model
        this.deivceTitle = option[0].title;

      }

    },
    onEvent(command) {
      if (this.device == "") {
        this.common.cToast(this, "请选择设备");
        return;
      }
      if (this.date == null || this.date.length == 0) {
        this.common.cToast(this, "请选择日期");
        return;
      }
      switch (command) {
        case "query":
          this.$emit('query', this.device, this.deviceModel, this.date, this.deviceTitle);
          break;
        case "excel":
          this.$emit('excel', this.device, this.deviceModel, this.date);
          break;
        default:
          break;
      }
    },
    getDate(n, fmt) { // 时间戳转换日期格式方法
      let day = new Date();
      day.setDate(day.getDate() - n);
      let o = {
        "M+": day.getMonth() + 1, //月份
        "d+": day.getDate(), //日
        "h+": day.getHours(), //小时
        "m+": day.getMinutes(), //分
        "s+": day.getSeconds(), //秒
        "q+": Math.floor((day.getMonth() + 3) / 3), //季度
        "S": day.getMilliseconds() //毫秒
      };
      if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (day.getFullYear() + "").substr(4 - RegExp.$1.length));
      for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      return fmt;
    }
  },
  mounted() {
    // 设备首选
    if (this.options.length > 0) {
      this.device = this.options[0].id;
      this.deviceModel = this.options[0].model;
      this.deviceTitle = this.options[0].title;
    }
    // 默认日期
    let date = this.common.cGetDate();
    this.date = [this.getDate(1, "yyyyMMdd"), date, this.startTime, this.stopTime];
  }
}
</script>

<style lang="scss">
.item {
  margin-bottom: 10px;
}
</style>
