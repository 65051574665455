<template>
  <div id="queryRain">
    <!-- 查询 -->
    <Query @query="query" :isExcel="false" :start-time="'200000'"></Query>
    <div class="c-container-box">
      <!-- 数据展示 -->
      <!-- 雨量数据 -->
      <el-table :data="rainData">
        <el-table-column prop="deviceName" label="设备名称" width="280"></el-table-column>
        <el-table-column prop="date" label="时间段" width="450"></el-table-column>
        <el-table-column prop="rain" label="雨量数据">
        </el-table-column>
      </el-table>
    </div>
    <div><span style="color: red;">*雨量统计目前可查水库、排涝和水闸设备。查询雨量时间段最小单位为“时”</span></div>
  </div>
</template>

<script>
import Query from '../../components/Query.vue';

export default {

  data() {
    return {
      // type: 'sensor', //sensor传感器数据，online在线离线数据——后台历史数据请求类型：8传感器，10设备上线，11设备离线
      device: '', //设备
      deviceName: '', //设备名称
      deviceModel: '', //设备model
      date: "", //日期区间
      // 设备字段/数据
      deviceFields: [],
      deviceData: [],
      rainData: [],
      loading: false,
    }
  },
  components: {
    Query
  },


  methods: {
    // query(device, deviceModel, date, deivceTitle) {
    //   console.log(333, device, deviceModel, date, deivceTitle);
    // },

    // 查询
    query(device, deviceModel, date, deivceTitle) {
      //切换了设备，清空数据
      if (deviceModel != "dmWater") {
        if (this.device !== device) {
          this.deviceFields = [];
          this.deviceData = []
        }
        this.device = device;
        this.deviceModel = deviceModel;
        this.deviceName = deivceTitle ? deivceTitle : device;
        this.date = date
        this.dataRequest();
      }

    },

    // 请求数据
    dataRequest() {
      let json = {
        "cmd": "queryRain",
        "uid": this.device,
        "startDate": this.date[0],//开始日期
        "startTime": this.date[2],//开始时间
        "stopDate": this.date[1],//结束日期
        "stopTime": this.date[3],//结束时间
        // "rain": Math.round(Math.random() * 10)      //测试雨量，部署前删除

      }
      // console.log("json:", json);
      this.ws.send(json);
      // this.loading = true;
    },


    //雨量查询返回
    queryRainAck() {
      this.ws.addCallback('queryRainAck', (res) => {
        this.loading = false;
        this.rainData = []//重新置空
        // console.log("time:", this.time);
        let date = this.date[0].replace(/^(\d{4})(\d{2})(\d{2})$/, "$1-$2-$3") + " " + this.date[2].replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3") + " 至 " + this.date[1].replace(/^(\d{4})(\d{2})(\d{2})$/, "$1-$2-$3") + " " + this.date[3].replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
        this.rainData.push({ "deviceName": this.deviceName, "rain": res.rain, "date": date })
        console.log("raindata:", res);
      })
    },


  },
  created() {
    this.queryRainAck();
  },
  mounted() {
    // 设备首选

  }
}
</script>

<style lang="scss">
#queryRain {
  .chart-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-row-gap: 1%; //行间距
    grid-column-gap: 1%; //列间距

    .chart {
      padding: 5%;
    }
  }

  // 重写element样式
  .el-date-editor--timerange {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .el-radio-group {
    margin-top: -3px;
  }

  .el-radio-button__inner {
    padding: 12px 15px;
  }

  .emty-class {
    color: #909399;
    text-align: center;
    padding: 20px 0;
  }
  .date {
    width: 130px !important;
    font-size: 12px !important;
    margin-left: 5px;
  }
  .demonstration {
    margin-left: 10px;
  }
}
</style>
